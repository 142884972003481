import React from "react"
import styled from "styled-components"

import MainLogo from "../../images/assets/logo-sgtt-inverted.svg"

const CustomLogo = styled.div`
  img {
    max-height: 70px;
  }
  /* Galaxy fold @ 280px wide (otherwise, used to be 320px) */
  @media only screen and (min-device-width: 280px) and (max-device-width: 480px) {
    img {
      max-width: 135px;
      margin-top: 10px;
    }
  }
  /* iPad */
  @media only screen and (min-device-width: 768px) and (max-device-width: 800px) {
    img {
      width: 240px;
      margin-top: 8px;
    }
  }
`

const LogoInverted = ({ logoClassName }) => (
  <CustomLogo>
    <img src={MainLogo} />
  </CustomLogo>
)

export default LogoInverted
