/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "../components/Header"
import "./index.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
          
          <footer>
            © {new Date().getFullYear()}, Sai Ganga Tours &amp; Travels
          </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


export const query = graphql`
         query siteTitleQueryAndSiteTitleQuery {
           site {
             siteMetadata {
               title
             }
           }
           allContentfulTestimonial {
             edges {
               node {
                 fullName
                 slug {
                   slug
                 }
               }
             }
           }
         }
       `