import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Link as ScrollLink } from "react-scroll"

import closeButton from "../../images/icon-close.svg"
import "./index.css"
import Logo from "../Logo";

import PhoneIcon from "../../images/phone-icon.svg"
import Menu from "../../images/menu.svg"
import MenuColourFilled from "../../images/menu-color-filled.svg"
import LogoInverted from "../LogoInverted"
import { MenuIcon } from "../MenuIcon"

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasScrolled: false,
      menuOverlayVisible: false,
    
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset
    if (scrollTop > 50) {
      this.setState(() => ({
        hasScrolled: true,
      }))
    } else
      this.setState(() => ({
        hasScrolled: false,
      }))
  }

  onMenuOverlayItemSelected = e => {
    // e.preventDefault();
    this.setState({
      menuOverlayVisible: false,
    })
  }
  render() {
    const { siteTitle } = this.props
    return (
      <div
        className={
          this.state.hasScrolled && !this.state.menuOverlayVisible
            ? "header-wrapper header-scrolled"
            : "header-wrapper"
        }
      >
        <header>
          <h1 className="logo">
            <Link
              to="/"
              style={
                this.state.hasScrolled
                  ? {
                      color: `#fefefe`,
                      textDecoration: `none`,
                      // fontSize: `24px`,
                    }
                  : {
                      color: `#333`,
                      textDecoration: `none`,
                      // fontSize: `24px`,
                    }
              }
            >
              {/* {siteTitle} */}
              {this.state.hasScrolled ? <LogoInverted /> : <Logo />}
            </Link>
          </h1>
          <div className="nav">
            <ScrollLink
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={50}
              duration={1300}
            >
              {/* <a href="#home">Home</a> */}{" "}
              {/* TODO: this is how it's done so as to not allow react to call
                    Warning: validateDOMNesting(...): */}{" "}
              <div className="nav--link"> Home </div>{" "}
            </ScrollLink>{" "}
            {/* <ScrollLink
              activeClass="active"
              to="about"
              spy={false}
              smooth={true}
              offset={-90}
              duration={1500}
            >
              <div className="nav--link about"> About </div>{" "}
            </ScrollLink>{" "} */}
            <ScrollLink
              activeClass="active"
              to="our-packages"
              spy={true}
              smooth={true}
              offset={-90}
              duration={1000}
            >
              <div className="nav--link"> Our Packages </div>{" "}
            </ScrollLink>{" "}
            {/* <ScrollLink
              activeClass="active"
              to="our-drivers"
              spy={true}
              smooth={true}
              offset={-90}
              duration={900}
            >
              <div className="nav--link"> Our drivers </div>{" "}
            </ScrollLink>{" "} */}
            <ScrollLink
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-90}
              duration={900}
            >
              <div className="nav--link"> Contact </div>{" "}
            </ScrollLink>{" "}
            <a href="tel:+91 98 6682 9116" className="nav--link contact-phone">
              <img src={PhoneIcon} alt="phone the taxi service" />
              <span> +91 98 6682 9116 </span>{" "}
            </a>{" "}
          </div>{" "}
          <div className="menu-xs menu-call-CTA hidden">
            <a href="tel:+91 98 6682 9116" className="nav--link contact-phone">
              <img src={PhoneIcon} alt="phone the taxi service" />
              <span>+91 98 6682 9116 </span>{" "}
            </a>
            {/* <span>MENU</span> */}
          </div>
          <div
            className="menu-xs hidden menu-icon"
            onClick={() =>
              this.setState({
                menuOverlayVisible: true,
              })
            }
          >
            {this.state.hasScrolled ? (
              <MenuIcon alt="menu icon" />
            ) : (
              <MenuIcon iconColour="#333" alt="menu icon" />
            )}
          </div>
          {/* MENU FOR MEDIA QUERIES  */}{" "}
          <div
            className={`menu-overlay ${
              this.state.menuOverlayVisible ? "" : "hidden"
            }`}
          >
            <h1> Menu </h1>{" "}
            <h2 onClick={this.onMenuOverlayItemSelected}>
              <img src={closeButton} alt="close" />
            </h2>{" "}
            <div className="menu">
              <ScrollLink
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={50}
                duration={1300}
              >
                <div onClick={this.onMenuOverlayItemSelected} href="#home">
                  Home{" "}
                </div>{" "}
              </ScrollLink>{" "}
              <ScrollLink
                activeClass="active"
                to="about"
                spy={false}
                smooth={true}
                offset={-90}
                duration={900}
              >
                <div onClick={this.onMenuOverlayItemSelected} href="#about">
                  About{" "}
                </div>{" "}
              </ScrollLink>{" "}
              <ScrollLink
                activeClass="active"
                to="our-packages"
                spy={true}
                smooth={true}
                offset={-90}
                duration={1000}
              >
                <div
                  onClick={this.onMenuOverlayItemSelected}
                  href="#our-packages"
                >
                  Our Packages{" "}
                </div>{" "}
              </ScrollLink>{" "}
              {/* <ScrollLink
                activeClass="active"
                to="our-drivers"
                spy={true}
                smooth={true}
                offset={-90}
                duration={1200}
              >
                <div onClick={this.onMenuOverlayItemSelected} href="#our-drivers">
                  Meet our drivers
                </div>
              </ScrollLink> */}
              <ScrollLink
                activeClass="active"
                to="testimonials"
                spy={true}
                smooth={true}
                offset={-90}
                duration={1200}
              >
                <div
                  onClick={this.onMenuOverlayItemSelected}
                  href="#testimonials"
                >
                  Testimonials
                </div>
              </ScrollLink>
              <ScrollLink
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-90}
                duration={1500}
              >
                <div onClick={this.onMenuOverlayItemSelected} href="#contact">
                  Contact
                </div>
              </ScrollLink>
            </div>{" "}
          </div>{" "}
        </header>{" "}
      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
